<template>
  <article>
    <div :class="['casino-container', {'open' : isOverviewOpen || isOpenReview}]">
      <button
        v-if="!isMorePageCasino"
        aria-label="Add to bookmarks"
        class="bookmark desktop"
        @click="toggleCasinoSubscription"
      >
        <BRIcon
          :class="{ 'bookmark-marked': isBookmarked }"
          :fill-color="isBookmarked ? 'var(--primary-icon-color)' : 'none'"
          :stroke="isBookmarked ? 'transparent' : 'var(--default-icon-color)'"
          height="15"
          icon="bookmark"
          width="11"
        />
      </button>
      <div :class="['main-information', {'un-ranked' : place > 3}]">
        <img
          :alt="casinoData.name"
          :src="getCasinoLogo"
          class="avatar"
          height="40"
          width="40"
        >
        <div class="general-info">
          <p class="casino-name">
            {{ casinoData.name }}
            <button
              v-if="!isMorePageCasino"
              aria-label="Add to bookmarks"
              class="bookmark"
              @click="toggleCasinoSubscription"
            >
              <BRIcon
                :class="{ 'bookmark-marked': isBookmarked }"
                :fill-color="isBookmarked ? 'var(--primary-icon-color)' : 'none'"
                :stroke="isBookmarked ? 'transparent' : 'var(--default-icon-color)'"
                height="12"
                icon="bookmark"
                width="9"
              />
            </button>
          </p>
          <div v-if="place < 4" aria-label="Rank" class="rank">
            <BRIcon
              :class="['icon-award', placeTitle[place]]"
              :height="$vuetify.breakpoint.xs ? '14' : '18'"
              :width="$vuetify.breakpoint.xs ? '14' : '18'"
              icon="award"
            />
            <p :class="placeTitle[place]" class="place">
              <span class="place-number">{{ getPlace }} </span> place
            </p>
          </div>
        </div>
      </div>
      <div v-if="casinoData.totalRating" aria-label="Rate" class="total-rate">
        <div>
          <span class="amount" :style="{ color: getRatingColor(casinoData.totalRating) }">
            {{ casinoData.totalRating }}</span>/<span class="of">5</span>
        </div>
        <BRIcon
          :height="$vuetify.breakpoint.xs ? '30' : '34'"
          :width="$vuetify.breakpoint.xs ? '26' : '30'"
          :style="{ color: getRatingColor(casinoData.totalRating) }"
          icon="rating"
        />
      </div>
      <ul
        v-if="casinoData.bonuses && casinoData.bonuses.length"
        aria-label="Bonuses"
        class="card-bonuses"
      >
        <li
          v-for="bonus in casinoData.bonuses.slice(0, 2)"
          :key="bonus.id"
          class="card-bonus-item"
        >
          <BRIcon
            fill-color="var(--primary-icon-color)"
            stroke="transparent"
            height="14"
            icon="hexagon"
            width="7"
          />
          <span class="text">{{ bonus.title }}</span>
        </li>
      </ul>

      <div class="preview-page">
        <BRButton
          :class="['preview-btn', {'btn-active' : isOverviewOpen || isOpenReview}]"
          :height="$vuetify.breakpoint.xs ? '35' : '45'"
          color="var(--primary-text-color)"
          outlined
          @click="isOverviewOpen = !isOverviewOpen"
        >
          <BRIcon
            :class="{ 'icon-active' : isOverviewOpen || isOpenReview }"
            height="16"
            icon="dropdown-arrow"
            width="16"
          />
          OVERVIEW
        </BRButton>
      </div>
    </div>

    <div v-if="isOverviewOpen || isOpenReview" class="content">
      <div class="details-card">
        <div class="wrap">
          <h4 class="overview-title">
            Total user rating
          </h4>
          <div class="property-list">
            <div class="card-rating-container">
              <div aria-label="Rate" class="total-rate dropdown-rate">
                <BRRatingHexagon
                  :current-rating="casinoData.totalRating"
                  read-only
                />
                <div>
                  <span
                    class="amount"
                    :style="{ color: getRatingColor(casinoData.totalRating) }"
                  >{{ casinoData.totalRating }}</span>/<span class="of">5</span>
                </div>
              </div>

              <p v-if="casinoData.userReviewsAmount" class="add-info">
                <span class="key-info" :style="{ color: getRatingColor(casinoData.totalRating) }">
                  {{ casinoData.totalRating }}
                </span> stars rating from <span
                  class="key-info"
                  :style="{ color: getRatingColor(casinoData.totalRating) }"
                >
                  {{ casinoData.userReviewsAmount }}
                </span> user reviews
              </p>
            </div>
            <h4 class="overview-title">
              Blockreviews rating
            </h4>
            <ul class="progress-rating-list">
              <li
                v-for="rating in ratings"
                :key="rating.field"
              >
                <BRRatingProgress
                  :progress="casinoData[rating.field]"
                >
                  {{ rating.title }}
                </BRRatingProgress>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="casinoData.pross || casinoData.cons"
        class="pross-cons details-card"
      >
        <div class="wrap">
          <div v-if="casinoData.pross && casinoData.pross.length">
            <h4 class="overview-title">
              Pross
            </h4>

            <div class="property-list">
              <div
                v-for="(pros, index) in casinoData.pross.slice(0, 6)"
                :key="index"
                class="property"
              >
                <template v-if="pros.trim()">
                  <BRIcon
                    class="icon"
                    height="14"
                    icon="pross"
                    width="16"
                  />
                  <span class="property-title">{{ pros }}</span>
                </template>
              </div>
            </div>
          </div>
          <div v-if="casinoData.cons && casinoData.cons.length">
            <h4 class="overview-title">
              Cons
            </h4>

            <div class="property-list">
              <div
                v-for="(con, index) in casinoData.cons"
                :key="index"
                class="property"
              >
                <template v-if="con.trim()">
                  <BRIcon class="icon" icon="cons" />
                  <span class="property-title">{{ con }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="details-card">
        <div class="wrap dropdown-bonuses">
          <div>
            <div v-if="casinoData.bonuses" class="casino-bonuses">
              <h4 class="overview-title">
                Bonuses
              </h4>

              <div class="property-list">
                <div
                  v-for="(bonus, index) in casinoData.bonuses"
                  :key="index"
                  class="property"
                >
                  <BRIcon class="icon" icon="bonus" themed />
                  <span class="bonus-title">{{ bonus.title }}</span>
                </div>
              </div>
            </div>

            <div class="casino-url">
              <h4 class="overview-title">
                Website
              </h4>
              <a :href="casinoData.website" class="url" target="_blank">
                {{ casinoData.website }}
              </a>
              <!--TODO: need real data-->
              <p class="add-info">
                Accept players from Poland
              </p>
            </div>

            <template v-if="getUserLocation">
              <p v-if="isCountryAccepted" class="acceptance">
                <BRIcon
                  class="icon"
                  height="12"
                  icon="check-green-circle"
                  width="12"
                />
                <span class="ml-1">Accept in your country</span>
              </p>
              <p v-else class="acceptance">
                <BRIcon
                  class="icon"
                  height="12"
                  icon="dismiss-red-circle"
                  width="12"
                />
                <span class="ml-1">Don’t accept in your country</span>
              </p>
            </template>
          </div>
          <div class="actions-container">
            <BRButton
              :height="$vuetify.breakpoint.xs ? '35' : '45'"
              :to="{ name: `${type}ItemPage`, params: { id: casinoData.id } }"
              class="review-button"
              outlined
            >
              OPEN A FULL REVIEW
            </BRButton>
            <BRButton
              v-if="casinoData.claimBonusLink"
              :height="$vuetify.breakpoint.xs ? '35' : '45'"
              :href="casinoData.claimBonusLink"
              class="review-button contrast"
              color="var(--primary-icon-color)"
              dark
            >
              CLAIM BONUS
            </BRButton>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import images from '@/mixins/image'
import ratingColor from '@/mixins/ratingColor'
import bookmarkCasino from '@/mixins/bookmarkCasino'
import BRRatingHexagon from '@/components/BRComponents/BRRatingHexagon.vue'
import BRRatingProgress from '@/components/BRComponents/BRRatingProgress'
import { getFormattedPlace } from '@/services/place'

export default {
  name: 'CasinoHorizontalCard',
  components: {
    BRRatingHexagon,
    BRRatingProgress
  },
  mixins: [images, bookmarkCasino, ratingColor],
  props: {
    place: {
      type: Number,
      required: true
    },
    casino: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'Casino'
    },
    isMorePageCasino: {
      type: Boolean,
      required: false,
      default: false
    },
    isBookmarked: {
      type: Boolean,
      required: false,
      default: false
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOverviewOpen: false,
      casinoData: this.casino,
      placeTitle: {
        1: 'first',
        2: 'second',
        3: 'third'
      },
      ratings: [
        { title: 'User friendly', field: 'userFriendly' },
        { title: 'Bonuses', field: 'bonusesRating' },
        { title: 'Payment methods & Simplicity', field: 'paymentMethodsSimplicity' },
        { title: 'Customer support', field: 'customerSupportRating' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getUserDB', 'getUserLocation']),
    isOpenReview() {
      return this.isOpen && this.place === 2
    },
    getCasinoLogo() {
      return this.casinoData.logo || '/img/default-image.webp'
    },
    isCountryAccepted() {
      // TODO: add countries
      return !this.casinoData.countries.find(country => country.shortName === this.getUserLocation)
    },
    getPlace() {
      return getFormattedPlace(this.place)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.pross-cons {
  .wrap {
    display: grid;
    place-content: space-between;
    gap: 30px;
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}

.dropdown-bonuses {
  display: grid;

  .property-list {
    .property {
      gap: 12px;
    }
  }
}

.overview-title {
  margin-bottom: 20px;
  font-size: var(--font-h4);
}

.progress-rating-list {
  display: grid;
  padding: 0;
  text-transform: uppercase;
  gap: 20px;
}

.casino-container {
  @include content;

  display: grid;
  align-items: center;
  grid-template-areas: "rank information rate bonus preview";
  grid-template-columns: 32px 2fr 120px 2fr 1fr;
  padding: 14px;
  gap: 4px;

  @include breakpoint-reverse(small) {
    align-items: flex-start;
    grid-gap: 18px 44px;
    grid-template-areas:
      "information rate"
      "bonus bonus"
      "preview preview";
    grid-template-columns: 1fr auto;
    padding: 14px 20px;
  }
}

.casino-container.open {
  box-shadow: 0 10px 60px rgba(52, 63, 116, 0.2);
}

[data-theme="dark"] .casino-container.open {
  box-shadow: none;
}

.preview-btn {
  border: none;
  background-color: var(--primary-bg-color);
  transition: background-color 0.3s, color 0.3s;

  ::v-deep .button-text {
    gap: 6px;
  }

  &.btn-active {
    background-color: var(--primary-button-bg-color);

    ::v-deep .button-text {
      color: var(--button-text-color);
    }
  }
}

.icon-active {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.general-info {
  width: 100%;
}

.rank {
  display: flex;
  grid-area: rank;
  gap: 4px;

  .bookmark-marked::v-deep .blockreviews-icon {
    stroke: var(--primary-icon-color);
  }

  @include breakpoint(medium) {
    align-items: center;
  }
}

.bookmark {
  display: flex;
  min-width: 9px;

  &::v-deep .blockreviews-icon {
    stroke: var(--text-color);
  }

  &.desktop {
    display: none;
    padding: 0 15px 0 6px;

    @include breakpoint(medium) {
      display: flex;
    }
  }

  @include breakpoint-reverse(small) {
    margin-left: 0;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.place {
  margin: 0;
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-sm);
  text-transform: uppercase;
  color: var(--secondary-text-color);
}

.main-information {
  display: flex;
  grid-area: information;
  gap: 12px;

  .casino-name {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
    gap: 10px;
  }

  @include breakpoint(medium) {
    gap: 20px;
  }
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: cover;

  @include breakpoint(medium) {
    width: 52px;
    height: 52px;
    min-width: 52px;
  }
}

.total-rate {
  display: flex;
  align-items: center;
  grid-area: rate;
  min-width: 60px;
  gap: 8px;

  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);
  }

  .of {
    font-size: var(--font-base);
  }
}

.total-rate:not(.dropdown-rate) {
  @include breakpoint(medium) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.dropdown-rate {
  margin-bottom: 14px;
  gap: 20px;

  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);
  }

  .of {
    font-size: var(--font-base);
  }

  @include breakpoint(medium) {
    gap: 12px;
  }
}

.card-rating-container {
  margin-bottom: 26px;

  .add-info {
    margin: 0;
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }

  .key-info {
    font-size: var(--font-base);
    color: var(--primary-text-color);
  }
}

.card-bonuses {
  display: grid;
  grid-area: bonus;
  padding: 0;
  gap: 10px;

  .text {
    @include subtitle;
  }

  @include breakpoint-reverse(small) {
    grid-column: 1 / 3;
  }
}

.card-bonus-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.preview-page {
  grid-area: preview;

  .blockreviews-button {
    width: 100%;

    @include breakpoint(medium) {
      width: 140px;
    }
  }

  .arrow-down {
    margin-left: 5px;
    max-width: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px 20px;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.details-card {
  @include breakpoint(medium) {
    flex: 1 1 30%;
  }
}

.wrap {
  @include content;

  padding: 20px 32px;
  height: 100%;
  box-shadow: 0 10px 50px rgba(52, 63, 116, 0.1);

  @include breakpoint(medium) {
    padding: 20px 25px 34px;
  }
}

.property-list {
  .property {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 16px auto;
    margin-top: 15px;
    font-size: var(--font-base);
    gap: 20px;

    .icon {
      display: block;
      width: 16px;
    }

    .bonus-title,
    .property-title {
      word-break: break-word;
    }
  }
}

.casino-bonuses {
  margin-bottom: 32px;

  @include breakpoint(medium) {
    margin-bottom: 38px;
  }
}

.casino-url {
  .url {
    font-size: var(--font-base);
    color: var(--link-color);
  }

  .overview-title {
    margin-bottom: 12px;
  }

  .add-info {
    margin: 12px 0 0;
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }
}

.acceptance {
  display: flex;
  margin: 10px 0 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  line-height: 12px;
}

.actions-container {
  display: grid;
  align-self: flex-end;
  margin-top: 30px;
  gap: 10px;
}

.review-button {
  width: 100%;

  &.contrast {
    color: var(--button-text-color);
  }
}

.icon-award {
  color: var(--primary-icon-color);

  &.first {
    color: var(--first-place-color);
  }

  &.second {
    color: var(--second-place-color);
  }

  &.third {
    color: var(--third-place-color);
  }
}

.un-ranked {
  align-items: center;
}

.place-number {
  text-transform: initial;
}
</style>
