<template>
  <section>
    <section v-if="headerItem" class="casinos">
      <section v-if="isNeedHeader" class="currency-header">
        <div class="currency">
          <div class="currency-image">
            <img
              :alt="headerItem.name"
              :src="getCurrencyIcon"
              class="image"
              height="100%"
              width="100%"
            >
          </div>
          <div class="information">
            <h1 class="currency-title">
              {{ headerItem.name }}
            </h1>
            <h4 class="currency-subtitle">
              {{ headerItem.description }}
            </h4>
          </div>
        </div>
        <div class="search">
          <BRInput
            :value.sync="searchParams.keyword"
            append-icon="mdi-close"
            class="search-input"
            placeholder="Best Casinos"
            @click:append="searchParams.keyword = ''"
          >
            <BRIcon
              class="search-icon"
              height="16"
              icon="search"
              width="16"
            />
          </BRInput>
          <button
            class="filter-button"
            @click="openFilterModal"
          >
            <BRIcon
              height="20"
              icon="preferences"
              width="20"
            />
          </button>
          <TagsMenu
            v-if="routeName === 'CasinoTag'"
            left
            open-on-click
          >
            <template #activator-element>
              <BRButton
                class="roulette"
                color="var(--secondary-button-bg-color)"
                height="40"
              >
                <BRIcon
                  class="arrow-icon"
                  height="4"
                  icon="arrow"
                  width="8"
                />
                {{ headerItem.name }}
              </BRButton>
            </template>
          </TagsMenu>
          <CurrenciesMenu v-if="routeName === 'CasinoCurrency' || routeName === 'Root'" left open-on-click>
            <template #activator-element>
              <BRButton
                class="roulette"
                color="var(--secondary-button-bg-color)"
                height="40"
              >
                <BRIcon
                  class="arrow-icon"
                  height="4"
                  icon="arrow"
                  width="8"
                />
                {{ headerItem.name }}
              </BRButton>
            </template>
          </CurrenciesMenu>
        </div>
      </section>
      <div class="casinos-data">
        <section class="casinos-header">
          <div class="bookmark">
            Follow
          </div>
          <div
            :class="[
              { 'desc': sortParams.sortDirection === 'desc' && sortParams.sortField === 'rank' },
              { 'asc': sortParams.sortDirection === 'asc' && sortParams.sortField === 'rank' }
            ]"
            class="main-info sort"
            @click="handleSort('rank')"
          >
            Rank
          </div>
          <div
            :class="[
              { 'desc': sortParams.sortDirection === 'desc' && sortParams.sortField === 'rating' },
              { 'asc': sortParams.sortDirection === 'asc' && sortParams.sortField === 'rating' }
            ]"
            class="total-rate sort"
            @click="handleSort('rating')"
          >
            Rating
          </div>
          <div class="best-bonus">
            Bonuses
          </div>
          <div class="preview-page">
            Actions
          </div>
        </section>
        <div v-if="!loading">
          <section class="casinos-list">
            <CasinoHorizontalCard
              v-for="(casino, index) in casinos.slice(0, casinosToShow)"
              :key="casino.id"
              :casino="casino"
              :is-bookmarked="casino.isBookmarked"
              :place="index + 1"
              class="casino"
              :is-open="isOpenCasino"
              @updateBookmark="updateBookmark"
            />
          </section>

          <section class="action">
            <BRButton
              v-if="isCanShowMore"
              dark
              color="var(--quaternary-button-bg-color)"
              width="100%"
              @click="currentPage++"
            >
              SHOW MORE
            </BRButton>
          </section>
        </div>
      </div>

      <div v-if="loading" class="d-flex align-center">
        <v-progress-circular
          class="loader-main"
          indeterminate
          size="50"
          width="5"
        />
      </div>
    </section>
    <FiltersModal :is-filter-modal-open.sync="isFilterModalOpen" @acceptFilters="acceptFilters" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import image from '@/mixins/image'
import CasinoHorizontalCard from '@/components/CasinoHorizontalCard'
import FiltersModal from '@/components/FiltersModal'
import api from '@/api'
import TagsMenu from '@/components/TagsMenu.vue'
import CurrenciesMenu from '@/components/CurrenciesMenu.vue'

export default {
  name: 'CasinosList',
  components: {
    CurrenciesMenu,
    TagsMenu,
    CasinoHorizontalCard,
    FiltersModal
  },
  mixins: [image],
  props: {
    pageSize: {
      type: Number,
      required: false,
      default: 10
    },
    isLimitCasinos: {
      type: Boolean,
      required: false,
      default: false
    },
    isNeedHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    isOpenCasino: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      headerItem: {
        id: 0,
        name: 'Cryptocurrency',
        description: 'Full list of casinos for all cryptocurrencies',
        icon: '/img/currency/cc.svg'
      },
      casinos: [],
      currentPage: 0,
      searchParams: {
        currencyName: '',
        keyword: '',
        filterByWithdrawMethod: '',
        filterByDepositMethod: '',
        filterByGames: '',
        filterByBonuses: ''
      },
      sortParams: {
        sortDirection: '',
        sortField: ''
      },
      isAddButtonDisabled: false,
      isFilterModalOpen: false
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    currentPageSlug() {
      return this.$route.params.slug
    },
    searchKeyword() {
      return this.searchParams.keyword
    },
    getCurrencyIcon() {
      return this.headerItem.icon || '/img/currency/cc.svg'
    },
    isCanShowMore() {
      return this.casinos.length > this.casinosToShow
    },
    casinosToShow() {
      return this.pageSize * (this.currentPage + 1)
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    searchKeyword(val) {
      if (!val) {
        this.setCasinos()
        return
      }
      this.casinos = this.casinos.filter(({ name }) => (name.toLowerCase().includes(val.toLowerCase())))
    },
    currentPageSlug() {
      this.resetCasinos()
    },
    $route() {
      this.setCasinos()
    }
  },
  mounted() {
    if (this.$route.query.keyword) {
      this.searchParams.keyword = this.$route.query.keyword
      return
    }
    this.setCurrentHeaderItem()
    this.setCasinos()
    this.fetchBookmarkInfo()
  },
  methods: {
    ...mapActions(['setIsNotFound']),
    setCasinos() {
      if (this.isLimitCasinos) {
        this.casinos = this.$contentful.reviews.slice(0, this.pageSize)
        return
      }

      if (!this.currentPageSlug) {
        this.casinos = [...this.$contentful.reviews]
        return
      }

      this.casinos = this.$contentful.reviews.filter(review => (
        this.$route.name === 'CasinoCurrency'
          ? !!review.currencies?.find(({ currency }) => currency?.slug === this.currentPageSlug)
          : !!review.tags?.find(({ tag }) => tag?.slug === this.currentPageSlug)
      ))
    },
    resetCasinos() {
      this.currentPage = 0
      this.setCurrentHeaderItem()
      this.setCasinos()
    },
    setCurrentHeaderItem() {
      let item = {
        id: 0,
        name: 'Cryptocurrency',
        description: 'Full list of casinos for all cryptocurrencies',
        icon: '/img/currency/cc.svg'
      }

      if (this.$route.name === 'CasinoCurrency') {
        item = this.$contentful.currencies?.find(({ slug }) => slug === this.currentPageSlug)
      } else if (this.$route.name === 'CasinoTag') {
        item = Object.values(this.$contentful.tags).flat().find(({ slug }) => slug === this.currentPageSlug)
      }

      if (!item) {
        this.setIsNotFound(true)
        return
      }

      this.searchParams.currencyName = item.slug
      this.headerItem = item
    },
    openFilterModal() {
      this.isFilterModalOpen = true
    },
    acceptFilters({
      checkedWithdraws,
      checkedDeposits,
      checkedGames,
      checkedBonuses
    }) {
      this.searchParams.filterByWithdrawMethod = checkedWithdraws.trim()
      this.searchParams.filterByDepositMethod = checkedDeposits.trim()
      this.searchParams.filterByGames = checkedGames.trim()
      this.searchParams.filterByBonuses = checkedBonuses.trim()

      this.resetCasinos()
    },
    async fetchBookmarkInfo() {
      const idsArray = this.casinos.map((casino) => casino.id)
      const idsList = idsArray.join(',')
      const { data } = await api.likes.getLikes(idsList)

      this.casinos = this.casinos.map((casino) => {
        casino.isBookmarked = data.find((item) => casino.id === item.itemId)?.isLiked || false
        return casino
      })
    },
    updateBookmark(value) {
      this.casinos = this.casinos.map((casino) => {
        if (casino.id !== value.id) return casino
        casino.isBookmarked = value?.data
        return casino
      })
    },
    handleSort(field) {
      // if sort by rating - descending first
      const sortDirections = field === 'rating' ? ['desc', 'asc'] : ['asc', 'desc']
      const sortBy = field === 'rating' ? 'totalRating' : 'name'
      if (this.sortParams.sortField === field) {
        const index = sortDirections.indexOf(this.sortParams.sortDirection)
        // we have 3 states: asc, desc, no sort
        // if we are in no sort state - reset casinos
        if (index === 1) {
          this.sortParams.sortField = ''
          this.sortParams.sortDirection = ''
          this.setCasinos()
          return
        }
        this.sortParams.sortDirection = sortDirections[index + 1]
      } else {
        // if we are sorting by another field - reset sort direction
        this.sortParams.sortField = field
        this.sortParams.sortDirection = sortDirections[0]
      }

      this.casinos = this.casinos.sort((a, b) => {
        if (this.sortParams.sortDirection === 'asc') {
          return a[sortBy] > b[sortBy] ? 1 : -1
        } else if (this.sortParams.sortDirection === 'desc') {
          return a[sortBy] < b[sortBy] ? 1 : -1
        } else {
          this.setCasinos()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casinos {
  @include container;

  display: grid;
  gap: 24px;
}

.currency-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 58px;

  @include breakpoint-reverse(medium) {
    flex-direction: column;
    margin: 0;
    gap: 32px;
  }
}

.currency {
  display: flex;
  align-items: center;
  align-self: flex-start;
  max-width: 565px;
  gap: 20px;

  .image {
    border-radius: 50%;
    width: 66px;
    height: 66px;
  }

  @include breakpoint-reverse(medium) {
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
  }
}

.information {
  .currency-title {
    margin-bottom: 10px;
    font-size: var(--font-h1);
  }

  .currency-subtitle {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-base);
    color: var(--secondary-text-color);

    @include breakpoint-reverse(medium) {
      max-width: 250px;
    }
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  .filter-button {
    margin-right: 15px;
    min-width: 20px;
  }

  .search-input {
    width: 100%;
    max-width: 200px;

    &::v-deep .v-input__control > .v-input__slot {
      padding: 12px 14px;
      gap: 10px;
    }

    &::v-deep .v-text-field__slot {
      font-size: var(--font-base);
    }

    @include breakpoint-reverse(medium) {
      max-width: 300px;
    }
  }

  @include breakpoint-reverse(medium) {
    justify-content: center;
    width: 100%;
  }
}

.casinos-data {
  display: grid;
  gap: 24px;
}

.casinos-header {
  display: grid;
  grid-template-columns: 32px 2fr 120px 2fr 1fr;
  padding: 0 16px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  user-select: none;
  gap: 4px;

  .sort {
    position: relative;
    cursor: pointer;

    &.desc::after,
    &.asc::after {
      content: "\F035D";
      display: inline-block;
      margin-left: 1px;
      font: var(--font-weight-normal) 16px/1 "Material Design Icons";
      line-height: 10px;
      color: var(--text-color);
      transform: rotate(0);
    }

    &.asc::after {
      transform: rotateX(180deg);
    }
  }

  .bookmark {
    padding: 0 15px 0 6px;
    text-align: center;
  }

  .main-info {
    padding-left: 72px;
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}

.casinos-list {
  .casino {
    margin-bottom: 10px;
  }
}

.action {
  margin-top: 20px;

  .blockreviews-button::v-deep .button-text {
    color: var(--secondary-text-color);
  }
}

.search-icon {
  color: var(--default-icon-color);
}

.roulette {
  display: none;
  border: none;
  width: 170px;
  color: var(--contrast-text-color);
  transition: background-color 0.3s, color 0.3s;

  ::v-deep .button-text {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      gap: 6px;
    }
  }

  .arrow-icon {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }

  @include breakpoint(medium) {
    display: block;
  }
}

.br-menu__activator.br-menu__activator_active {
  .arrow-icon {
    transform: rotate(0);
  }
}

</style>
