<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-top-transition"
    max-width="940"
    content-class="filters-modal"
    @click:outside="closeModal"
  >
    <div class="modal-header">
      <span class="modal-title">Filters</span>
      <p class="modal-description">
        Just small description to tell your customers what you will see on this page
        and how it work with crypto casinos bla bla… Just small description to tell your customers
      </p>
      <button class="close-button" @click="closeModal">
        <BRIcon
          icon="close"
          width="20"
          height="20"
          fill-color="var(--default-icon-color)"
        />
      </button>
    </div>
    <div ref="filters">
      <div v-if="!$vuetify.breakpoint.xs" class="modal-body">
        <div class="filters-table">
          <div class="table-column">
            <div class="column-title">
              Bonuses
            </div>
            <div class="column-filters">
              <div v-for="(bonus, i) in bonusCategories" :key="i">
                <input
                  :id="bonus"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedBonuses', bonus)"
                >
                <label :for="bonus">{{ bonus }}</label>
              </div>
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">
              Deposit method
            </div>
            <div class="column-filters">
              <div v-for="(deposit, i) in payments" :key="i">
                <input
                  :id="deposit.id"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedDeposits', deposit.id)"
                >
                <label :for="deposit.id">{{ deposit.name }}</label>
              </div>
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">
              Withdraw method
            </div>
            <div class="column-filters">
              <div v-for="(withdraw, i) in payments" :key="i">
                <input
                  :id="i"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedWithdraws', withdraw.id)"
                >
                <label :for="i">{{ withdraw.name }}</label>
              </div>
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">
              Games
            </div>
            <div class="column-filters">
              <div v-for="(game, i) in gameCategories" :key="i">
                <input
                  :id="game"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedGames', game)"
                >
                <label :for="game">{{ game }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="modal-body-mobile">
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="column-title">
                Bonuses
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(bonus, i) in bonusCategories" :key="i">
                <input
                  :id="bonus"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedBonuses', bonus)"
                >
                <label :for="bonus">{{ bonus }}</label>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="column-title">
                Deposit method
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(deposit, i) in payments" :key="i">
                <input
                  :id="deposit.id"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedDeposits', deposit.id)"
                >
                <label :for="deposit.id">{{ deposit.name }}</label>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="column-title">
                Withdraw method
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(withdraw, i) in payments" :key="i">
                <input
                  :id="i"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedWithdraws', withdraw.id)"
                >
                <label :for="i">{{ withdraw.name }}</label>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="column-title">
                Games
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(game, i) in gameCategories" :key="i">
                <input
                  :id="game"
                  type="checkbox"
                  class="filter-checkbox"
                  @change="checkFilter($event, 'checkedGames', game)"
                >
                <label :for="game">{{ game }}</label>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div class="modal-footer">
      <BRButton
        outlined
        color="var(--secondary-text-color)"
        class="clear-button"
        @click="clearFilters"
      >
        CLEAR ALL
      </BRButton>
      <BRButton
        outlined
        color="var(--secondary-text-color)"
        class="cancel-button"
        @click="cancelModal"
      >
        CANCEL
      </BRButton>
      <BRButton dark class="accept-button" @click="acceptFilters">
        ACCEPT FILTERS
      </BRButton>
    </div>
  </v-dialog>
</template>

<script>
import bonusCategories from '../../public/data/bonusCategories.json'
import gameCategories from '../../public/data/gameCategories.json'
import payments from '../../public/data/payments.json'

export default {
  name: 'FiltersModal',
  props: {
    isFilterModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      bonusCategories,
      gameCategories,
      payments,
      checkedBonuses: [],
      checkedGames: [],
      checkedDeposits: [],
      checkedWithdraws: []
    }
  },
  watch: {
    isFilterModalOpen(value) {
      this.isOpen = value
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:isFilterModalOpen', false)
    },
    cancelModal() {
      this.clearFilters()
      this.acceptFilters()
      this.closeModal()
    },
    clearFilters() {
      this.checkedBonuses = []
      this.checkedGames = []
      this.checkedDeposits = []
      this.checkedWithdraws = []

      const checkedFilters = this.$refs.filters.querySelectorAll('.filter-checkbox:checked')

      checkedFilters.forEach((checkbox) => {
        checkbox.checked = false
      })
    },
    acceptFilters() {
      this.closeModal()
      this.$emit('acceptFilters', {
        checkedWithdraws: this.checkedWithdraws.join(','),
        checkedDeposits: this.checkedDeposits.join(','),
        checkedGames: this.checkedGames.join(','),
        checkedBonuses: this.checkedBonuses.join(',')
      })
    },
    checkFilter({ target }, filterCategory, value) {
      if (target.checked) {
        this[filterCategory].push(value)
        return
      }

      this[filterCategory] = this[filterCategory].filter(item => item !== value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.v-dialog__content::v-deep .filters-modal {
  overflow: hidden;
  overflow-y: auto;
  border-radius: var(--border-radius-default);
  padding: 24px 16px 16px;
  height: auto;
  background-color: var(--content-bg-color);
}

.modal-header {
  position: relative;
  margin-bottom: 18px;

  .modal-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h1);
    color: var(--text-color);
  }

  .modal-description {
    max-width: 470px;
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }

  .close-button {
    position: absolute;
    right: 0;
    top: -8px;
    width: 20px;
    height: 20px;

    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.modal-body,
.modal-body-mobile {
  margin-bottom: 23px;

  .filters-table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .table-column {
    border-right: var(--default-border);
    color: var(--text-color);

    &:first-child {
      .column-title,
      .column-filters {
        padding-left: 0;
      }
    }

    &:last-child {
      border-right: none;
    }
  }

  .column-title {
    border-bottom: var(--default-border);
    padding: 0 23px 18px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .column-filters {
    display: grid;
    grid-gap: 15px;
    overflow: hidden;
    overflow-y: auto;
    padding: 23px;
    max-height: 340px;
    font-size: var(--font-base);
    scrollbar-color: var(--tertiary-bg-color) var(--tertiary-bg-color);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: var(--tertiary-bg-color);
    }
  }

  .filter-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;

    + label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    + label::before {
      content: "";
      display: block;
      margin-right: 11px;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      background-color: var(--input-alt-bg-color);
    }

    &:checked + label::before {
      background-image: url("/img/checkbox.svg");
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: var(--primary-text-color);
    }
  }

  &::v-deep .v-expansion-panel {
    color: var(--text-color);
    background-color: transparent;

    .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
      color: var(--text-color);
    }
  }
}

[data-theme="dark"] {
  .v-dialog__content::v-deep .filters-modal {
    box-shadow: none;
  }

  .modal-body,
  .modal-body-mobile {
    .filter-checkbox:checked + label::before {
      background-image: url("/img/checkbox-dark.svg");
    }
  }
}

.modal-body-mobile {
  .v-expansion-panels::v-deep {
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .column-title {
    padding: 10px 0;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  @include breakpoint-reverse(small) {
    flex-direction: column;

    .blockreviews-button {
      &.clear-button,
      &.cancel-button,
      &.accept-button {
        width: 100%;
      }
    }
  }
}
</style>
