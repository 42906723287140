export const getFormattedPlace = (number) => {
  const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' })

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th']
  ])

  const rule = pluralRules.select(number)
  const suffix = suffixes.get(rule)
  return `${number}${suffix}`
}
